;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var listingsSlider = function() {
		return $('.js-impress-listings-slider').slick({
			slidesToShow: 1,
			centerMode: true,
			autoplay: true,
			autoplaySpeed: 7000,
			speed: 1000,
			fade: false,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 750,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
					}
				}
			]
		});
	}

	var testimonialsSlider = function() {
		return $('.js-testimonials').slick({
			autoplay: true,
			autoplaySpeed: 7000,
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});
	}

	var heroSlider = function() {
		return $('.js-backgrounds').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});
	}

	var imagePopups = function() {
		$('.rgg-imagegrid').magnificPopup({
			mainClass: 'mfp-fade',
			delegate: 'a', 
			type: 'image',
			titleSrc: 'title',
			gallery: {
				enabled: true
			}
		});
	}

	$(document).ready(function() {
		testimonialsSlider();
		heroSlider();
		imagePopups();
		listingsSlider();
	});

	$(document).ready(function() {
		$('.page-template-template-homepage iframe[src*="youtube.com"]').wrap('<div class="responsive-video"></div>');
		$('.page-template-template-homepage iframe[src*="youtube-nocookie.com"]').wrap('<div class="responsive-video"></div>');
		$('.page-template-template-homepage iframe[src*="player.vimeo.com"]').wrap('<div class="responsive-video"></div>');
	});

})(jQuery);

(function($) {

	$('#js-navToggle, #mobileMenu-close, #mobileMenu-layer-close').click(function (e) {
		e.preventDefault();
		toggleNav();
	});

	var toggleNav = function() {
		var currentStatus = $('html').attr('data-mobile-menu-is');
		if (currentStatus === 'open') {
			$('html').attr('data-mobile-menu-is', 'closed');
		} else {
			$('html').attr('data-mobile-menu-is', 'open');
		}
	}

	var check = function() {

		$('html').attr('data-mobile-nav-status', '');

		var navContainer, navItems, containerWidth;
		navContainer = $('.site-navigation__expanded');

		if (navContainer.length > 0) {

			containerWidth = navContainer.outerWidth();
			navItems = $(navContainer.selector + ' > li');
			var sumOfItemWidths = 0;

			for (var i = 0, len = navItems.length; i < len; i++) {
				var item, width;
				item = $(navItems[i]);
				width = item.width();
				sumOfItemWidths += parseInt(width);
			}

			if (sumOfItemWidths > containerWidth) {
				$('html').attr('data-mobile-nav-status', 'mobile');
			} else {
				$('html').attr('data-mobile-nav-status', 'expanded');
			}
		}
	}

	setTimeout(function() {
		$(document).ready(check);
	}, 500);

	$(window).resize(check);

	var toggleSubMenu = function(e) {
		$(this).toggleClass('open');
		$(this).next('.sub-menu').slideToggle();
	}

	var setDowndownIcons = function() {
		var parents = $('#mobileMenu .menu-item-has-children');
		parents.each(function(i) {
			$(parents[i]).children('a').after('<div class="mobileMenu-dropdown"></div>');
		});
	}

	$(document).ready(setDowndownIcons);
	$(document).on('click', '.mobileMenu-dropdown', toggleSubMenu);

})(jQuery);

;(function($) {
	
	var stickyNavSetup = function() {
		var nav = $('[data-fixed-masthead]');
		var navHeight = nav.outerHeight();

		nav.wrap('<div id="masthead-wrapper"></div>');
		$('#masthead-wrapper').height(navHeight);
	}

	var stickyNavHeightFix = function() {
		var nav = $('[data-fixed-masthead]');
		var navHeight = nav.outerHeight();
		$('#masthead-wrapper').height(navHeight);
	}

	var stickyNavScroll = function() {
		if ( $('#masthead-wrapper').length === 0 ) { return; }
		var distanceToScroll = $('#masthead-wrapper').offset().top;
		var distanceScrolled = $(window).scrollTop();
		if ( distanceScrolled >= distanceToScroll ) {
			$('html').addClass('masthead-is-stuck');
		} else {
			$('html').removeClass('masthead-is-stuck');
		}
	}

	$(document).ready(function() {
		stickyNavSetup();
		setTimeout(stickyNavSetup, 1000);
	});

	$(window).scroll(function() {
		window.requestAnimationFrame(stickyNavScroll);
	});

	$(window).resize(function() {
		stickyNavHeightFix();
		window.requestAnimationFrame(stickyNavScroll);
	});

})(jQuery);